<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const value = computed({
  get: () => props.modelValue,
  set: (val: boolean) => {
    emit('update:modelValue', val);
  }
});
</script>


<template>
	<w-modal v-model="value" :transition="false" class="w-full">
		<div class="p-3 text-center flex flex-col gap-3">
			<h3 class="text-gray-900 text-2xl my-3">Send a request</h3>
			<img :src="`images/${appId}/logo-blue.webp`" alt="" class="my-2 w-auto h-8 object-contain">
			<layout-email-form />
		</div>
	</w-modal>
</template>